import "@cb/apricot/CBBase";
import "@cb/apricot/CBLogo";
import "@cb/apricot/CBTable";
import "@cb/apricot/CBCard";
import "@cb/athena-widgets-types";
import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.scss";
import AppProvider from "./App.Provider";
import Landing from "./Landing";
import Upload from "./Upload";
import Transcript from "./Transcript";
import UserInfo from "./UserInfo";
import AppContext from "./App.Context";

const App: React.FC = () => {
  return (
    <AppProvider>
      <main id="main-container" className={`main-container`}>
        <div className="App">
          <cbw-header
            app-id="1234"
            skip-target-id=""
            use-login-widget="false"
          ></cbw-header>
          <BrowserRouter>
            <div className="pages-wrapper container display-flex">
              <div className="">
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/userinfo" element={<UserInfo />} />
                  <Route path="/upload" element={<Upload />} />
                  <Route path="/transcript" element={<Transcript />} />
                </Routes>
              </div>
            </div>
          </BrowserRouter>
          <cbw-footer style={{ backgroundColor: "transparent" }}></cbw-footer>
        </div>
      </main>
    </AppProvider>
  );
};

export default App;
