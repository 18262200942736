import { useContext } from "react";
import {
  SimpleAccordion,
  SimpleAccordionItem,
  SimpleAccordionHeader,
  SimpleAccordionPanel,
} from "@cb/apricot-react-accordion";
import { Table, Column } from "@cb/apricot-react-table";
import AppContext from "../../pages/App.Context";
import TableHeader from "./Table-Header";

const MiniTable = () => {
  const { academicHistory } = useContext(AppContext).userInfo;

  const accordionTable = (
    <SimpleAccordion
      index={2}
      single={false}
      title="Transcript Information"
      controls
    >
      {academicHistory?.map((item, index) => (
        <SimpleAccordionItem panelOverlay={true} key={index}>
          <SimpleAccordionHeader
            className="cb-no-text-decoration-icon  cb-blue5-tint-1"
            iconPosition="right"
          >
            <div className="cb-no-text-decoration-icon">
              <h3 className="category cb-font-size cb-font-weight-bold">
                {item.category.replace(/([a-z])([A-Z])/g, "$1 $2")}
              </h3>
            </div>
          </SimpleAccordionHeader>
          <SimpleAccordionPanel>
            <Table
              tableId="tableIdOptional"
              data={item.classes}
              tabIndex={0}
              condensed={false}
              borderless={false}
              striped
              rowSelected={(r) => r.age >= 30 && r.age <= 50}
              rowHighlighted={(r) => r.age >= 70 && r.age <= 90}
              sortType="inline"
              stickyHeaderParent
              stickyColumn
              maxHeight={600}
              loader={false}
              overflowX={false}
              responsive
            >
              <Column
                field="title"
                title="Title"
                // component={Name}
                rowHeader
                sticky
              />
              <Column
                field="date"
                title="Date"
                // component={Name}
                rowHeader
                sticky
              />
              <Column field="mark" title="Mark" />
              <Column
                field="credit"
                title="Credit"
                //  headerComponent={AgeHeader}
                className="age-cell"
                sortable
              />
              <Column field="gl" title="GL" />
            </Table>
          </SimpleAccordionPanel>
        </SimpleAccordionItem>
      ))}
    </SimpleAccordion>
  );

  return (
    <div>
      <TableHeader />
      <div className="accordion-header-wrapper cb-padding-16 cb-border cb-border-gray-1">
        {accordionTable}
      </div>
    </div>
  );
};

export default MiniTable;
