import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MiniTable from "../components/accordionTable";
import PageHeader from "../components/Page-Header/Page-Header";
import "./style.scss";
import AppContext from "./App.Context";

const Transcript: React.FC = () => {
  const navigate = useNavigate();
  const { isLoading, phoneNumber, userInfo, getUserData } =
    useContext(AppContext);
  const { academicHistory, highSchoolInfo } = userInfo;

  useEffect(() => {
    if (phoneNumber && !isLoading && !highSchoolInfo) {
      getUserData(phoneNumber);
    }
  }, [phoneNumber, isLoading]);

  useEffect(() => {
    if (!isLoading && !phoneNumber) {
      navigate("/userinfo");
    }
  }, [isLoading, phoneNumber, academicHistory]);

  return (
    <div className="transcript-wrapper">
      <PageHeader />
      <div className="row col-md-7 col-lg-7 justify-content-center cb-margin-sm-up-left-72  cb-padding-sm-up-left-72  cb-padding-sm-up-right-72  cb-margin-sm-up-right-72  cb-margin-bottom-72  cb-margin-top-24">
        <MiniTable />
      </div>
    </div>
  );
};

export default Transcript;
