import React from "react";
import UploadForm from "../components/Upload-Form/Upload-Form";
import PageHeader from "../components/Page-Header/Page-Header";

const Upload: React.FC = () => {
  return (
    <>
      <PageHeader />
      <UploadForm />
    </>
  );
};

export default Upload;
