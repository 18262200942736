import AWS from 'aws-sdk';

const lambda = new AWS.Lambda({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
    sessionToken: process.env.REACT_APP_AWS_SESSION_TOKEN as string,
  },
});

export const getUserRecord = async (phoneNumber: string) => {
  if (!phoneNumber) return false;
  const params = {
    FunctionName: 'my-textextract-project-dev-retrieveRecord',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ phoneNo: phoneNumber }),
  };

  try {
    const resp = await lambda.invoke(params).promise();

    return JSON.parse(resp.Payload as string);
  } catch (error) {
    console.error(error);
    return false;
  }
};
