import React from "react";
import { Breadcrumbs } from "../Breadcrumbs/breadcrumbs";
import "./Page-Header.scss";

const PageHeader = () => {
  return (
    <>
      <div className="page-header cb-blue5-bg cb-padding-sm-up-72 cb-padding-sm-down-16 cb-margin-auto cb-width-100 cb-align-left cb-roboto">
        <div className="page-header-content cb-white-color">
          <h2 className=" cb-roboto cb-h2 cb-font-weight-bold cb-margin-bottom-8">
            Upload High School Transcript Data
          </h2>
          <p>
            Submission of transcript data updates your institution’s Big Future
            profile. To view your current profile at the Big Future website,
            click{" "}
            <a className="cb-white-color cb-no-text-decoration" href="/#">
              here
            </a>
            .
          </p>
        </div>
      </div>
      <Breadcrumbs />
    </>
  );
};

export default PageHeader;
