import { ButtonRow, PrimaryButton } from '@cb/apricot-react-button';
import { Input } from '@cb/apricot-react-forms';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { getUserRecord } from '../services/get-user-info';
import AppContext from './App.Context';

const UserInfo: React.FC = () => {
  const navigate = useNavigate();
  const { phoneNumber, setPhoneNumber, userInfo, isLoading, isDataFetched, getUserData } = useContext(AppContext);
  const { highSchoolInfo } = userInfo;

  useEffect(()=> {
    if(isDataFetched && phoneNumber && !isLoading && highSchoolInfo) {
      navigate('/transcript');
    }

    if(isDataFetched && phoneNumber && !isLoading && !highSchoolInfo) {
      navigate('/upload');
    }
  }, [isLoading, highSchoolInfo]);

  const handlePhoneChange = (e: any) => {
    setPhoneNumber && setPhoneNumber(e?.target?.value ?? "");
  };

  const onSubmit = async (e: any) => {
    window.localStorage.setItem('phoneNumber', phoneNumber);
    
    getUserData(phoneNumber);
  };

  const userInfoContent = () => {
    return (
      <div className="cta-card-wrapper cb-margin-top-72 cb-padding-top-32 cb-padding-bottom-32">
        <div
          className="cb-card cb-card-cta cb-white-bg cb-black1-color cb-margin-24"
          data-cbtrack-comp="apricot-vanilla:card"
        >
          <div className="cb-card-header justify-content-center">
            <span
              className="cb-ilus cb-ilus-monotone cb-ilus-raised-pencil cb-ilus-custom-circular cb-white-bg cb-ilus-72"
              aria-hidden="true"
            />
          </div>
          <div className="cb-card-content cb-align-center">
            <h3 className="cb-font-weight-light cb-margin-bottom-24">
              Enter The Phone Number
            </h3>
            <p>
              To begin the transcript upload process, please enter your phone
              number below.
            </p>
            <div className="cb-card-action  display-flex flex-row justify-content-center cb-margin-16"></div>
            <p>Phone Number: {phoneNumber}</p>
            <div className="cb-padding-16">
              <Input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneChange}
                name="phone"
                placeholder="Phone Number"
                className="cb-spacerh-24 cb-width-100 "
              />
            </div>
            <ButtonRow>
              <PrimaryButton type="submit" onClick={onSubmit}>
                Submit
              </PrimaryButton>
            </ButtonRow>
          </div>
        </div>
      </div>
    );
  };
  return <div> {userInfoContent()}</div>;
};

export default UserInfo;
