import { FC, useRef } from 'react';
import { ErrorNotification, SuccessNotification } from '@cb/apricot-react';

type Props = {
    isError: boolean;
    title: string;
    message: string;
    onDismiss: () => void;
};

export const Notification: FC<Props> = ({isError, title, message, onDismiss}) => {
    const Wrapper = isError ? ErrorNotification : SuccessNotification;
    const ref = useRef(null);

    return (
        <Wrapper
            ref={ref}
            className='notification-body container'
            title={<div className='notification-title cb-h4'>{title}</div>}
            dismissible
            onDismiss={onDismiss}>
            {message}
        </Wrapper>
    )
};

export default Notification;