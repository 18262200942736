import { FileUpload, PrimaryButton, Spinner } from "@cb/apricot-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../pages/App.Context";
import { uploadToS3 } from "../../services/s3-upload";
import Notification from "../Notification/Notification";
import "./Upload-Form.scss";
import { getUserRecord } from "../../services/get-user-info";

const UploadForm = () => {
  const navigate = useNavigate();
  const {
    phoneNumber,
    isLoading,
    setIsLoading,
    userInfo: { academicHistory, highSchoolInfo },
    isDataFetched,
    setUserInfo,
  } = useContext(AppContext);
  const [fileData, setFileData] = useState<Record<string, string> | null>(null);
  const [notificationData, setNotificationData] = useState<null | {
    isError: boolean;
    title: string;
    message: string;
  }>(null);

  useEffect(() => {
    if (!isLoading && !phoneNumber) {
      navigate("/userinfo");
    }
  }, [isLoading, phoneNumber, academicHistory]);

  useEffect(() => {
    if (isDataFetched && phoneNumber && !isLoading && highSchoolInfo) {
      navigate("/transcript");
    }
  }, [isLoading, highSchoolInfo]);

  const handleFileSelect = (event: any) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileData({
          name: uploadedFile.name,
          type: uploadedFile.type,
          size: uploadedFile.size,
          data: reader.result as string,
        });
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const removeFile = () => setFileData(null);

  const onNotificationDismiss = () => setNotificationData(null);

  const handleSubmit = async () => {
    try {
      if (!fileData || !phoneNumber) {
        setNotificationData({
          isError: true,
          title: "Missing Phone Number",
          message: "Please log in again.",
        });
        return;
      }

      const resp = await uploadToS3({
        fileContent: fileData,
        phoneNumber,
      });

      if (!resp) {
        throw new Error("Failed to upload");
      }

      setNotificationData({
        isError: false,
        title: "Successful upload!",
        message: "Your transcript was successfully upload.",
      });

      setIsLoading(true);
      const pollUserRecord = async () => {
        try {
          const userRecord = await getUserRecord(phoneNumber);
          console.log(userRecord);
          if (userRecord && userRecord.length > 0) {
            setUserInfo(userRecord[0])
            navigate("/transcript");
          } else {
            setTimeout(pollUserRecord, 1000); // Poll again after 1 seconds
          }
        } catch (error) {
          console.log(error);
          setNotificationData({
            isError: true,
            title: "Error",
            message: "Failed to fetch user record.",
          });
        }
      };

      setTimeout(pollUserRecord, 500);
    } catch (error) {
      console.log(error);
      setNotificationData({
        isError: true,
        title: "Upload Failed",
        message: "Please try again.",
      });
    }
  };
  const newUploadContent = () => {
    return (
      <div className="cta-card-wrapper cb-margin-top-72 cb-padding-top-32 cb-padding-bottom-32">
        <div
          className="cb-card cb-card-cta cb-white-bg cb-black1-color cb-margin-24"
          data-cbtrack-comp="apricot-vanilla:card"
        >
          <div className="cb-card-header justify-content-center">
            <span
              className="cb-ilus cb-ilus-monotone cb-ilus-raised-pencil cb-ilus-custom-circular cb-white-bg cb-ilus-72"
              aria-hidden="true"
            />
          </div>
          <div className="cb-card-content cb-align-center">
            <h3 className="cb-font-weight-light cb-margin-bottom-24">
              Upload Transcript File
            </h3>
            <p className="cb-padding-16 ">
              Upload an image of your high school transcript below. Acceptable
              file types are pdf, tiff, jpg, and png. Individual file size
              cannot exceed 5MB.
            </p>
            <div className="cb-padding-16">
              <FileUpload
                btnType="primary"
                label="Select Files to Upload"
                onFileSelected={handleFileSelect}
                btnClassName="inquiry-upload-button"
                onFileRemoved={removeFile}
              />
            </div>
            <PrimaryButton onClick={handleSubmit} disabled={!fileData}>
              Submit
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {notificationData && (
        <Notification onDismiss={onNotificationDismiss} {...notificationData} />
      )}
      {isLoading ? (
        <div className="cb-spinner-div cb-margin-top-72">
          <Spinner />
        </div>
      ) : (
        <div>{newUploadContent()}</div>
      )}
    </>
  );
};

export default UploadForm;
