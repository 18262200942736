import React, { useContext } from "react";
import { useNavigate } from "react-router";

const Landing: React.FC = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/userinfo");
  };

  const ctaCard22 = () => {
    return (
      <div className="cta-card-wrapper cb-margin-top-72 cb-padding-top-32 cb-padding-bottom-32">
        <div
          className="cb-card cb-card-cta cb-white-bg cb-black1-color cb-margin-24"
          data-cbtrack-comp="apricot-vanilla:card"
        >
          <div className="cb-card-header justify-content-center">
            <span
              className="cb-ilus cb-ilus-monotone cb-ilus-raised-pencil cb-ilus-custom-circular cb-white-bg cb-ilus-72"
              aria-hidden="true"
            />
          </div>
          <div className="cb-card-content cb-align-center">
            <h3 className="cb-font-weight-light cb-margin-bottom-24">
              Upload Your Transcript
            </h3>
            <p>Make study time a part of your daily routine.</p>
            <div className="cb-card-action justify-content-center cb-margin-16">
              <button className="cb-btn cb-btn-primary" onClick={onClick}>
                Sign in to Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div> {ctaCard22()} </div>
    </>
  );
};

export default Landing;
