import AWS from "aws-sdk";

const lambda = new AWS.Lambda({
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
    sessionToken: process.env.REACT_APP_AWS_SESSION_TOKEN as string,
  },
});

interface S3UploadParams {
  fileContent: Record<string, string> | null;
  phoneNumber: string;
}

export const uploadToS3 = async ({
  fileContent,
  phoneNumber,
}: S3UploadParams) => {
  const params = {
    FunctionName: "team05-infra-dev-S3UploadHandler",
    InvocationType: "RequestResponse",
    Payload: JSON.stringify({ phoneNumber, fileContent }),
  };

  try {
    const respObj = await lambda.invoke(params).promise();
    const resp = JSON.parse(respObj.Payload as string);
    if (resp.statusCode !== 200) {
      throw new Error(resp);
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
