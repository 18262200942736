import { useContext } from "react";
import { SpacerH } from "@cb/apricot-react-spacer";
import AppContext from "../../pages/App.Context";
import "./style.scss";

const TableHeader = () => {
  const { highSchoolInfo } = useContext(AppContext).userInfo;

  const contentContainer = () => {
    const userData = highSchoolInfo
      ? Object.entries(highSchoolInfo).map(([key, value]) => {
          if (key.toString() === "pii") {
            return Object.entries(highSchoolInfo?.pii).map(([key, value]) => {
              return (
                <div className="content-wrapper">
                  <div className="key-label cb-padding-left-8 cb-padding-right-8 cb-padding-top-4 cb-font-weight-bold cb-text-caption">
                    {key} :
                  </div>
                  <SpacerH size="24" />
                  <div className="value-label cb-padding-left-8 cb-padding-right-8 cb-padding-top-4">
                    {value?.toString() ?? ""}
                  </div>
                </div>
              );
            });
          }
          return (
            <div className="content-wrapper">
              <div className="key-label cb-padding-left-8 cb-padding-right-8 cb-padding-top-4 cb-font-weight-bold cb-text-caption">
                {key} :
              </div>
              <SpacerH size="8" />
              <div className=" value-label cb-padding-left-8 cb-padding-right-8 cb-padding-top-4">
                {value?.toString() ?? ""}
              </div>
            </div>
          );
        })
      : null;

    return (
      <>
        <h3 className="cb-roboto cb-font-weight-regular cb-padding-16">
          Student Information
        </h3>
        <div className="table-header-content cb-padding-16"> {userData} </div>
      </>
    );
  };

  return (
    <div className="header-wrapper">
      <div className="display-flex flex-row cb-border  cb-border-gray-1">
        {contentContainer()}
        <SpacerH size="32" />
      </div>
    </div>
  );
};

export default TableHeader;
