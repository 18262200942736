import { FC, ReactNode, useEffect, useState } from 'react';
import AppContext from './App.Context';
import { getUserRecord } from '../services/get-user-info';

type Props = {
  children: ReactNode;
};
const AppProvider: FC<Props> = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem("phoneNumber") ?? ""
  );
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  
  useEffect(() => {
    if(phoneNumber && !isDataFetched) {
        getUserData(phoneNumber);
    }
  }, []);

  const getUserData = async (phoneNumber: string) => {
    setIsLoading(true);
    try {
      const resp = await getUserRecord(phoneNumber);
      // if record is found navigate to transcript
      if (resp && (resp as []).length > 0) {
        setUserInfo(resp[0]);
      }
      setIsLoading(false);
      setIsDataFetched(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppContext.Provider 
        value={{ 
            phoneNumber,
            setPhoneNumber,
            userInfo,
            setUserInfo,
            isLoading,
            isDataFetched,
            setIsLoading,
            setIsDataFetched,
            getUserData
        }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
