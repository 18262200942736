import "./breadcrumbs.scss";

import {
  Breadcrumbs as ApricotBreadcrumbs,
  Crumb,
} from "@cb/apricot-react-breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";

interface PathNameObj {
  buttonId?: string;
  label: string;
  route: string;
}

export const Breadcrumbs: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let rootPath = "";
  const pathnames: Array<PathNameObj> = [{ label: "Home", route: "/" }];
  let pageTitle = pathnames[0]["label"];

  const locationPaths = location.pathname.replace(rootPath, "").split("/");

  for (let i = 0; i < locationPaths.length; i++) {
    const route = `/${locationPaths.slice(0, i + 1).join("/")}`;
    const lastPath = locationPaths.length - 1 === i;

    //replace all non alphanumeric chars with space
    let label = locationPaths[i].replace(/[^a-zA-Z0-9]+/g, " ");

    if (lastPath) {
      pageTitle = label;
    } else {
      pathnames.push({ label: label, route: `/${rootPath}${route}` });
    }
  }

  const handleClick = (route: string) => {
    navigate(route);
  };

  const crumbs = pathnames
    .filter((path) => path.label)
    .map((path) => (
      <Crumb
        key={path.label}
        title={path.label}
        buttonId={path.buttonId ?? ""}
        onClick={() => handleClick(path.route)}
      />
    ))
    .filter(Boolean);

  return (
    <div className="breadcrumbs cb-blue5-tint-1 cb-roboto cb-padding-left-72 cb-padding-right-72 cb-padding-top-24 cb-padding-bottom-24 cb-text-caption cb-font-size-regular">
      <ApricotBreadcrumbs pageTitle={pageTitle}>{crumbs}</ApricotBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
