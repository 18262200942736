import { createContext } from "react";
import {
  HighSchoolInfo,
  AcademicStanding,
  AcademicHistoryItem,
} from "../types/transcript";

export type Props = {
  isLoading: boolean;
  isDataFetched: boolean;
  phoneNumber: string;
  userInfo: {
    highSchoolInfo?: HighSchoolInfo | null;
    academicStanding?: AcademicStanding | null;
    academicHistory?: AcademicHistoryItem[];
  };
  setUserInfo: (val: any) => void;
  setPhoneNumber: (val: string) => void;
  setIsLoading: (val: boolean) => void;
  setIsDataFetched: (val: boolean) => void;
  getUserData: (phoneNumber: string) => void;
};

// Create a context with a default value
const AppContext = createContext<Props>({
  isLoading: false,
  isDataFetched: false,
  phoneNumber: "",
  userInfo: {
    highSchoolInfo: null,
    academicStanding: null,
    academicHistory: [],
  },
  setUserInfo: () => {},
  setPhoneNumber: () => {},
  setIsLoading: () => {},
  setIsDataFetched: () => {},
  getUserData: () => {}
});

export default AppContext;
